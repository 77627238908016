import {
  ArticleContent,
  ArticleDetails,
  ArticleHeader,
  ArticleHeaders,
  ArticleThumbnail,
  ArticleTimeIcon,
  ArticleTitle,
  ArticleType,
  ArticlesWrapper,
  AuthorBox,
  AuthorImg,
  ReadingTime
} from './styled.components';
import { Column, Row } from '../../components/global/utils';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useContext } from 'react';
import {
  faFacebookSquare,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

import Breadcrumbs from '../../components/global/breadcrumbs';
import Layout from '../../components/layout/layout';
import MentoringModal from '../../components/modals/mentoring/MentoringModal';
import { Page } from '../../components/global/page';
import { PageWrapper } from '../../views/news/styled.components';
import { RecommendedElements } from '../components/recommended-elements';
import { SectionWrapper } from '../../components/global/flexbox';
import Seo from '../../components/seo';
import { ThemeProvider } from 'styled-components';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import getCurrentTranslations from '../../components/contentful-translator';
import { graphql } from 'gatsby';
import moment from 'moment';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { richTextRenderOptions } from '../../utils/templateRenderOptions';
import { theme } from '../../themes/default';

export default function ArticlePage({ data, pageContext }) {
  const [lastThreeArticles, setLastThreeArticles] = React.useState();
  const { article } = pageContext;

  const sortedArray = (array) => {
    return array.sort((a, b) => {
      const dateA = new Date(a.node.publishDate);
      const dateB = new Date(b.node.publishDate);
      return dateB - dateA;
    });
  };
  const { language } = useContext(I18nextContext);

  React.useEffect(() => {
    const getLastThreeArticles = () => {
      const articles = getCurrentTranslations(
        data.allContentfulArticle.edges,
        language
      );

      if (articles) {
        const articlesWithoutDuplicates = articles.filter(
          (item) => item.node.title !== article.title
        );
        const sortedArticles = sortedArray(articlesWithoutDuplicates);

        if (article.category !== null) {
          var articlesByCategory = [];
          articlesByCategory = sortedArticles.filter(
            (item) => item.node.category === article.category
          );
          if (articlesByCategory.length < 3) {
            setLastThreeArticles(sortedArticles.slice(0, 3));
          } else {
            setLastThreeArticles(articlesByCategory.slice(0, 3));
          }
        } else {
          setLastThreeArticles(sortedArticles.slice(0, 3));
        }
      }
    };
    getLastThreeArticles();
  }, [article, data.allContentfulArticle, language]);

  const { t } = useTranslation();
  const breadcrumbsPath = [
    { name: t('breadcrumbs.news'), path: '/news/' },
    { name: t('breadcrumbs.articles'), path: '/news/articles' },
    {
      name: t('breadcrumbs.article'),
      path: `articles/${article.slug}`
    }
  ];
  return (
    <ThemeProvider theme={theme}>
      <Layout withHeader={false}>
        <Seo
          title={article.title + '| Innovations Academy'}
          description={t`news.seo.description`}
          ogImage={article.thumbnail.file.url}
        />
        <Page className="articles" style={{ marginLeft: 0, marginRight: 0 }}>
          <PageWrapper>
            <ArticlesWrapper className="border-bottom">
              <ArticleHeaders>
                <ArticleHeader>
                  <Breadcrumbs path={breadcrumbsPath} />
                  <ArticleType>{article?.titleInfo}</ArticleType>
                  <ArticleTitle>{article.title}</ArticleTitle>
                </ArticleHeader>
                <ArticleDetails shouldWrap={true}>
                  {article?.authors?.map((item) => {
                    return (
                      <AuthorBox>
                        <AuthorImg src={item?.file?.url}></AuthorImg>
                        <Column>
                          <strong>{item?.title}</strong>
                          <ReadingTime>{item?.description}</ReadingTime>
                        </Column>
                      </AuthorBox>
                    );
                  })}
                  <AuthorBox className="no-border">
                    <ArticleTimeIcon className="circle" icon={faClock} />
                    <Column>
                      <div>
                        {moment(article?.publishDate).format('DD.MM.YYYY')}
                      </div>
                      <ReadingTime>
                        {String(article.readTime + ' ' + t('read_time'))}
                      </ReadingTime>
                    </Column>
                  </AuthorBox>
                  <AuthorBox className="no-border end">
                    <Column>
                      <span>{t`template-article-share`}</span>
                      <Row>
                        <FacebookShareButton
                          hashtag={article.slug}
                          url={encodeURI(
                            'https://www.innovationshub.pl/news/articles/' +
                              article.slug
                          )}>
                          <ArticleTimeIcon icon={faFacebookSquare} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                          title={String(article.title)}
                          summary={String(article.titleInfo)}
                          source={String(
                            'https://innovationshub.pl/news/articles/' +
                              article.slug
                          )}>
                          <ArticleTimeIcon icon={faLinkedinIn} />
                        </LinkedinShareButton>
                      </Row>
                    </Column>
                  </AuthorBox>
                </ArticleDetails>
              </ArticleHeaders>
            </ArticlesWrapper>
            <ArticlesWrapper>
              <ArticleThumbnail src={article.thumbnail.file.url} />
              <SectionWrapper>
                <ArticleContent>
                  {renderRichText(article.content, richTextRenderOptions)}
                </ArticleContent>
              </SectionWrapper>
            </ArticlesWrapper>
            <RecommendedElements
              elements={lastThreeArticles}
              type={'articles'}
            />
          </PageWrapper>
          {pageContext.article.shouldShowMentoringModal && <MentoringModal />}
        </Page>
      </Layout>
    </ThemeProvider>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulArticle {
      edges {
        node {
          title
          slug
          node_locale
          thumbnail {
            file {
              url
            }
          }
          authors {
            title
          }
          publishDate
          category
        }
      }
    }
  }
`;
